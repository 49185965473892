.advantage-wrap{
    background-color: white;
    text-align: center;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    padding: 0 24px 0 24px;
}
.advantage-item{
    background-color: #C8DCF9;
    margin-bottom: 12px;
}
.advantage-item-left{
    float: left;
    font-size: 60px;
    padding: 8px;
    
    .number{
        width: 50px;
        height: 70px;
        line-height: 80px;
        background-color: #FAF9F9;
        text-align: center;
    }
}

.advantage-item-right{
    text-align: left;
    padding: 6px;
    .text{
        line-height: 24px;
        color: #333D68;
        font-size: 18px;
        .advantage-item-right-line1{
            font-weight: 500;
        }
        .advantage-item-right-line2{
            font-size: 16px;
        }
    }
}