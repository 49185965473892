.talent-grid-wrap{
    text-align: center;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 300;
    color:#333D68;
}
.talent-grid-item{
    padding-top: 4px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    height: 200px;
    width: 160px;
}
.talent-info-name{
    font-size: 18px;
    margin: 10px 0 6px 0;
}
.talent-info-extends{
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    margin: 2px 0 2px 0;
}