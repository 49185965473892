
.service-wrap{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 300;
}

.service-items{
    display: flex;
    align-items: center;
    justify-content: center;
}
.service-item{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    height: 130px;
    width: 160px;
    border: 1px solid #EFEFEF;
    margin-bottom: 8px;
    padding: 10px 4px 10px 4px;
}

.service-info{
    font-size: 18px;
    margin: 6px 0 6px 0;
}
.service-info-extends{
    font-size: 16px;
    text-align: center;
}