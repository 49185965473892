.solution-img{
    text-align: center;
}

.solution-items{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 300;
}

.solution-item{
    text-align: left;
    color: #333D68;
    font-size: 18px;
    .text-title{
        padding-left: 10px;
        font-weight: bold;
        line-height: 25px;
    }
    .text-items{
        border-left: 2px solid #0065F8;
        padding-left: 10px;
        .text-item{
            font-size: 16px;
        }
    }
    
    margin-bottom: 10px;
}