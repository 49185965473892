.requirement-wrap{
    padding: 21px;
}
.requirement-title{
    height: 28px;
    font-size: 20px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #0065F8;
    text-align: center;
    margin-top: 23px;
}
.requirement-title-tips{
    font-size: 16px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 300;
    color: #6A7196;
    text-align: center;
    margin-top: 7px;
}
.requirement-area-title{
    height: 25px;
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #101736;
    line-height: 25px;
    margin-top: 21px;
    margin-bottom: 14px;
}
.requirement-description{
    border-radius: 8px;    
    border: 1px solid #EEEFF6;
    
}

.am-list-item {

    padding: 0;

}
.am-textarea-control{
    padding: 12px 12px 0 12px;
}

.am-list-item:not(:last-child) .am-list-line {
    border-bottom: none;
}

.requirement-item{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0 10px 0;
}
.requirement-item-title{
    width: 120px;
    height: 25px;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #101736;
    line-height: 25px;
}
.requirement-item-input{
    flex: auto;
    height: 40px;
    background: #FFFFFF;
    border-radius: 8px;
    border: 1px solid #EEEFF6;
    padding-left: 16px;
}

.requirement-item-right{
    flex: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
        .requirement-item-right-input{
            width: 80px;
            margin-right: 5px;
            height: 40px;
            background: #FFFFFF;
            border-radius: 8px;
            border: 1px solid #EEEFF6;
            padding-left: 16px;
    }
}
// .requirement-item-right{
//     display: flex;
//     align-items: center;
//     justify-content: center;
    
//     .requirement-item-right-input{
//         width: 90px;
//         margin-right: 5px;
//         height: 40px;
//         background: #FFFFFF;
//         border-radius: 8px;
//         border: 1px solid #EEEFF6;
//         padding-left: 16px;
//     }
// }
.requirement-agreement{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 14px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 300;
    .checkbox{
        width: 18px;
        height: 18px;
        background: #EE4E3B;
        touch-action: none;
        border: 1px solid #EE4E3B;
    }
}
.requirement-agreement-agree-text{
    margin-left: 8px;
    color: #101736;
}
.requirement-agreement-detail{
    color: #EE4E3B
}
.requirement-btn-submit{
    margin-top:30px;
    border-radius: 8px;
    font-size: 18px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 300;
}