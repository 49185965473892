.agreement-wrap{
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 300;
    line-height: 30px;
    font-size: 16px;
    padding: 20px;
    -moz-user-select: none;
    -o-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.title{
    text-align: center;
    line-height: 66px;
    font-size: 22px;
    font-weight: bold;
}
.level-1-title{
    font-size: 18px;
    line-height: 54px;
    font-weight: bold;
}