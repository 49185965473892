.home-wrap{
    background-color: #FAF9F9;
}
.big-title{
    background-color: white;
    height: 65px;
    line-height: 65px;
    text-align: center;
    font-size: 20px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #0065F8;
}