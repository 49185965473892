
.introduction-wrap{
    padding: 0 24px 0 24px;
    text-align: center;
    background-color: white;
    font-size: 16px;
    color: #101736;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 300;
}
.introduction-detail{
    padding: 15px;
    border: 1px solid #0065F8;
    margin-top: 15px;
    p{
        text-align: left;
        letter-spacing: 2px;
        line-height: 17px;
    }
}